import Home from "../Home";
import Work from "../Work";
import About from "../About";

function Main() {
  return (
    <>
      <Home />
      <About />
      <Work />
    </>
  );
}

export default Main;
